import { useEffect } from 'react';

const KEYPRIVATE_REDIRECTS = {
  en: '/en/investing/keyprivate/',
  fr: '/fr/investir/keyprivate/',
  nl: '/nl/beleggen/keyprivate/',
};

const DEFAULT_LANG = 'en';

const getRedirectLanguage = () => {
  if (typeof navigator === 'undefined') {
    return DEFAULT_LANG;
  }

  const lang =
    navigator && navigator.language && navigator.language.split('-')[0];
  if (!lang) return DEFAULT_LANG;

  switch (lang) {
    case 'fr':
      return 'fr';
    case 'nl':
      return 'nl';
    default:
      return DEFAULT_LANG;
  }
};

const IndexPage: any = () => {
  useEffect(() => {
    const urlLang = getRedirectLanguage();

    window.location.href = KEYPRIVATE_REDIRECTS[urlLang];
  }, []);

  return null;
};

export default IndexPage;
